import React from 'react'
import Header from './Header'
import Footer from './Footer'
import AlreadyWorkWithUs from './AlreadyWorkWithUs'
import InterestedInJoining from './InterestedInJoining'

export default function Artists() {
  return (
    <>
      <Header />

      <InterestedInJoining />

      <AlreadyWorkWithUs />

      <Footer />
    </>
  )
}
