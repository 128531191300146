import React, { useState, useRef } from 'react'
import play from '../Assets/icons/play.png'
import pause from '../Assets/icons/pause.png'
import mute from '../Assets/icons/mute.png'
import sound from '../Assets/icons/sound.png'
import './Preview.css'

export default function Preview() {

    const [muted, setMuted] = useState(false)
    const [playing, setPlaying] = useState(false)

    const videoRef = useRef()

    const togglePlay = () =>{
      if(playing){
        setPlaying(false)
        videoRef.current.pause()
      } else {
        setPlaying(true)
        videoRef.current.play()
      }
    }

  return (
    <div id='preview' style={{maxWidth: 1600, margin: '0 auto'}}>
      <video ref={videoRef} src={'https://music-gofer-resources.s3.eu-west-2.amazonaws.com/video(compressed).mp4'}  width='100%' height='100%' muted={muted} autoPlay={false} loop playsInline/>
      <img className='play-btn hover'   src={playing ? pause : play} onClick={togglePlay} alt='Music Gofer Play Live Music in Manchester Bar Video'/>
      <img className='mute-btn hover'   src={muted ? mute : sound} onClick={() => setMuted(!muted)} alt='Music Gofer Pause Live Music in Manchester Bar Video'/>
    </div>
      
  )
}
