import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import Hero from '../Components/Hero';
import Header from '../Components/Header';
import SideTab from '../Components/SideTab';
import Keyboard from '../Components/Keyboard';
import singer from '../Assets/singer.webp';
import Preview from './Preview';
import Promo from './Promo';
import Contact from './Contact';
import Benefits from './Benefits';
import Footer from './Footer';

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate from react-router-dom

  const handleClickScroll = (id) => {
    // Check if the target is home, and navigate to "/" without hash
    if (id === 'home') {
      navigate('/'); // Navigate to root path
    } else {
      // Scroll to the section if it's not home
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        
        // Remove hash from URL after scrolling (for non-home sections)
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }
  };

  useEffect(() => {
    // Trigger scroll to element if there's a hash in the URL
    if (location.hash) {
      const sectionId = location.hash.replace('#', ''); // Strip out the `#`
      handleClickScroll(sectionId); // Scroll to the element or navigate to home
    }
  }, [location]);

  return (
    <>
      <Header />
      <SideTab />
      <Hero />
      <Keyboard />

      <div style={{ padding: '4rem 2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', background: 'linear-gradient(130deg, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%)' }}>
        <p style={{ width: 1000, maxWidth: '90vw', color: '#F47FDF', textAlign: 'center', fontSize: 24 }}>
          At Music Gofer our passion is to infuse your space with the perfect soundtrack...
        </p>
        <button
          className='mg-btn'
          style={{ marginTop: 50 }}
          onClick={() => handleClickScroll('contact')}
        >
          Book A Call
        </button>
      </div>

      <Preview />
      <Benefits />
      <Promo />
      <Contact />
      <img style={{ width: '100vw', height: 900, objectFit: 'cover', objectPosition: 'top left' }} src={singer} />
      <Footer />
    </>
  );
}
