import React from 'react'
import './Artists.css'
import apple from '../Assets/icons/apple.png'
import google from '../Assets/icons/google.png'

export default function AlreadyWorkWithUs() {
  return (
    <div id='already-work-with-us'>
      <div id='work-with-us-container'>
        <h2>Already work with us?</h2>
        <p>Download our app today to make managing your gigs even easier!</p>
        <div id='app-icon-container'>
          <a className='app-link' href='https://play.google.com/store/apps/details?id=com.musicgofer.musicgofer' target='_blank'>
            <img className='app-store-icon' src={google} alt='Music Gofer Live Music Agency App Store'/>
          </a>
          <a className='app-link' href='https://apps.apple.com/uk/app/music-gofer/id1619329943' target='_blank'>
            <img className='app-store-icon' src={apple} alt='Music Gofer Live Music Agency App Store'/>
          </a>
        </div>
      </div>
      
    </div>
  )
}
