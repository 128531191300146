import React, { useState} from 'react'
import './Artists.css'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function InterestedInJoining() {

  const [name, setName ] = useState("")
    const [email, setEmail ] = useState("")
    const [number, setNumber ] = useState("")
    const [msg, setMsg ] = useState("")
    const [loading, setLoading ] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!name || !email || !number || !msg){
            toast.error("Please add all fields")
        } else {
            let payload = {
                name, email, number, msg
            }
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/music-gofer/contact`, payload)
            .then(() => {
                toast.success("Successfully sent")
                setName("");setEmail("");setNumber("");setMsg("")
            })
            .catch(() => {
                toast.error("Something went wrong")
            })
            .finally(() => {
                setLoading(false)
            })
        }
    }

  return (
    <div id='interested'>
      <div id='interested-container'>

        <div id='interested-left'>
          <h2>Interested in joining our roster?</h2>
          <p>We’re excited to hear from you! Fill out the form an we’ll be in touch. Before reaching out, please ensure you meet the following criteria:</p>
          <ul>
            <li>A repertoire of at least 2 hours of popular cover songs.</li>
            <li>Your own equipment (PA system is recommended but not required).</li>
            <li>Promotional materials including at least one video link.</li>
          </ul>
          <p>We appreciate your interest and look forward to the possibility of working together!</p>
        </div>

        <form id='interested-right'>
            <input className='form-input' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
            <input className='form-input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <input className='form-input' placeholder='Phone' value={number} onChange={(e) => setNumber(e.target.value)} />
            <textarea 
              className='form-input' 
              placeholder='Message' 
              style={{resize: 'none', height: 140, paddingTop: 20, marginBottom: 5}}
              value={msg} 
              onChange={(e) => setMsg(e.target.value)}
            />            
            <p style={{color: '#fff', fontSize: 12, textAlign:'left', width: '100%', margin: '10px 0'}}>By submitting I consent to allow Music Gofer to store and process the personal information submitted above to communicate with me regarding Music Gofer services.</p>
            <button 
              id='contact-btn' 
              style={{marginTop: 5}} 
              disabled={loading}                  
              onClick={handleSubmit}
            >
              {loading? "Sending..." :"Send"}
            </button>
        </form>

      </div>
      
    </div>
  )
}
