import React, { useState } from 'react'
import './Contact.css'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Contact() {

    const [name, setName ] = useState("")
    const [email, setEmail ] = useState("")
    const [number, setNumber ] = useState("")
    const [msg, setMsg ] = useState("")
    const [loading, setLoading ] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!name || !email || !number || !msg){
            toast.error("Please add all fields")
        } else {
            let payload = {
                name, email, number, msg
            }
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/music-gofer/contact`, payload)
            .then(() => {
                toast.success("Successfully sent")
                setName("");setEmail("");setNumber("");setMsg("")
            })
            .catch(() => {
                toast.error("Something went wrong")
            })
            .finally(() => {
                setLoading(false)
            })
        }
    }

  return (
    <div id='contact'>
        <h2>Book a chat with us</h2>
        <p>Discover how we can elevate your venue with exceptional live music. Fill out the contact form now and we’ll be in touch to schedule a free consultation.</p>

        <form id='contact-form'>
            <div id='contact-left'>
                <input className='form-input' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                <input className='form-input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input className='form-input' placeholder='Phone' value={number} onChange={(e) => setNumber(e.target.value)} />
            </div>
            <div id='contact-right'>
                <textarea 
                    className='form-input' 
                    placeholder='Message' 
                    style={{resize: 'none', height: 140, paddingTop: 20, marginBottom: 5}}
                    value={msg} 
                    onChange={(e) => setMsg(e.target.value)}
                />
                <p style={{color: '#fff', fontSize: 12, textAlign:'left', width: '100%', margin: 0}}>By submitting I consent to allow Music Gofer to store and process the personal information submitted above to communicate with me regarding Music Gofer services.</p>
                <button 
                    id='contact-btn' 
                    style={{marginTop: 5}} 
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    {loading? "Sending..." :"Send"}
                </button>
            </div>

        </form>
    </div>
  )
}
