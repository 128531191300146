import React from 'react'
import './Footer.css'
import logo from '../Assets/logo1.png'
import logo2 from '../Assets/logo2.png'
import fb from '../Assets/icons/fb.png'
import insta from '../Assets/icons/insta.png'
import youtube from '../Assets/icons/youtube.png'
import linkedin from '../Assets/icons/linkedin.png'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer>
        <div>
            <img style={{width: 300, maxWidth:'80vw'}}  src={logo2}/>
            <h3 style={{width: 300, maxWidth:'80vw'}}>Supplying unbeatable atmosphere since 2016</h3>
        </div>

        <img style={{width: 200, maxWidth: '40vw', margin: '20px 0'}}  src={logo}/>

        <div >
            <div id='socials'>
              <a href='https://www.instagram.com/musicgofertv/' target='_blank'>
                <img className='social-icon' src={insta} alt='Music Gofer live music agency social media'/>
              </a>
              <a href='https://www.facebook.com/musicgofer' target='_blank'>
                <img className='social-icon' src={fb} alt='Music Gofer live music agency social media'/>
              </a>
              <a href='https://www.youtube.com/channel/UCQdJkxhOFzB6vNYWiOA2a_w' target='_blank'>
                <img className='social-icon' src={youtube} alt='Music Gofer live music agency social media'/>
              </a> 
              <a href='https://www.linkedin.com/company/music-gofer' target='_blank'>
                <img className='social-icon' src={linkedin} alt='Music Gofer live music agency social media'/>
              </a>
            </div>

            <Link to={'/t-c-s'} className='footer-link'>Terms & Conditions</Link>
            <a href='https://frantech.org.uk' target={'_blank'} className='footer-link'>Website by Frantech</a>

        </div>
        
    </footer>
  )
}
