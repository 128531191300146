import React from 'react'
import './Hero.css'

export default function Hero() {

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id='hero'>
        <h2>We Supply live music to bars and restaurants</h2>
        <button id='hero-btn' onClick={() => handleClickScroll('keyboard')}>Learn More</button>
    </div>
  )
}
