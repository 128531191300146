import React, { useState } from 'react';
import './Header.css';
import logo from '../Assets/logo1.png';
import logo2 from '../Assets/logo2.png';
import { useNavigate, useLocation, Link } from 'react-router-dom';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (id) => {
    setIsMenuOpen(false);

    // If already on the homepage, scroll directly
    if (location.pathname === '/') {
      handleClickScroll(id);
    } else {
      // Navigate to the homepage with hash
      navigate(`/#${id}`);
    }
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <header>
        <Link to={'/'}>
          <img id="header-logo" src={logo} alt="Music Gofer Live Music Agency Manchester Logo" />
        </Link>
        <Link to={'/'}>
          <img id="header-logo-name" src={logo2} alt="Music Gofer Live Music Agency Manchester Logo" />
        </Link>
        <div className="hamburger-icon" onClick={toggleMenu} id="icon">
          <div className={`icon-1 ${isMenuOpen ? 'a' : ''}`} id="a"></div>
          <div className={`icon-2 ${isMenuOpen ? 'c' : ''}`} id="b"></div>
          <div className={`icon-3 ${isMenuOpen ? 'b' : ''}`} id="c"></div>
        </div>
      </header>

      <nav id="nav" className={isMenuOpen ? 'show' : ''}>
        <ul>
          <li onClick={() => handleNavClick('home')}>Home</li>
          <li onClick={() => handleNavClick('keyboard')}>About</li>
          <li onClick={() => handleNavClick('contact')}>Contact Us</li>
          <Link to={'/artists'} onClick={() => setIsMenuOpen(false)}>
            <li style={{ color: '#F47FDF' }}>Are you a musician?</li>
          </Link>
        </ul>
      </nav>
    </>
  );
}
