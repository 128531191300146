import React from 'react'
import './Terms.css'

export default function Terms() {
    
      return (

      <div id='terms'>
    
        <h3>MUSIC GOFER LIMITED</h3>
        <h4>TERMS AND CONDITIONS</h4>
    
          <p>
          These terms and conditions constitute our agreement with you for the hire of Artists. These
          are referred to as our “Terms and Conditions” and apply to all bookings whether made orally,
          electronically or in writing.
          If you do not understand any part of these Terms and Conditions, please contact the Agency
          for further clarification or seek appropriate legal advice prior to confirming a booking with the
          Agency.<br/><br/>
          <span style={{color:'#F47FDF', fontWeight:'bold'}}>The Client’s attention is drawn specifically to clauses 4, 5, 6 and 11 of these Terms and
          Conditions</span></p>
    
          <p>
          1. DEFINITIONS AND INTERPRETATION
          The following definitions shall apply to these Terms and Conditions:
          Agency means Music Gofer Limited (company no: 10459019) whose registered office is 2
          Stone Cottages, Brookhouse Lane, Congleton, CW12 3QS.
          Artist means any performer or service required for entertainment as booked by the Client via
          the Agency.
          Client means any individual or company that has provided a booking instruction to the Agency
          for an Artist.
          Contract means the agreement between the Agency and the Client for supply of the Artist to
          the Client’s venue in accordance with these Terms and Conditions.
          Electronic/Electronically means any written information received or sent by e-mail.
          Written/Writing includes Electronic form to the following email addresses –
          niel@musicgofer.co.uk and/ or doug@musicgofer.co.uk and/ or demi@musicgofer.co.uk
          </p>
    
          <p>
          2. BOOKING CONFIRMATION<br />
          2.1 Booking are confirmed upon oral, Electronic or written acceptance of a booking by both
          the Client and the Agency. Once the booking is confirmed the parties shall be bound by
          these Terms and Conditions.<br />
          2.2 Notwithstanding clause 2.1, the Client’s continued instruction of the Agency in relation to
          a booking shall constitute acceptance of these Terms and Conditions. 
          </p>
    
          <p>
          3. CLIENT OBLIGATIONS<br />
          The Client shall:<br />
          3.1 provide on booking full details of the required performance including venue details,
          timings and any necessary contacts details;<br />
          3.2 advise on booking of any sound restrictions at the venue or any other restrictions which
          may affect an Artist’s access or performance;<br />
          3.3 ensure adequate parking facilities are available for the Artist’s vehicles with good
          access to loading/unloading facilities;<br />
          3.4 ensure Artists have access to suitable and secure changing facilities;<br />
          3.5 provide an appropriate and safe environment to perform in;<br />
          3.6 ensure an adequate power supply is available where required;<br />
          3.7 make payment without delay in accordance with clause 4;<br />
          3.8 comply in a timely manner with any other reasonable requirements or requests of the<br />
          Agency or the Artist.
          </p>
          <p>
          4. PAYMENT<br />
          4.1 Unless otherwise agreed between the parties, payment shall be invoiced and become
          payable by the Client in accordance with the booking request provided to the Client.<br />
          4.2 Unless otherwise agreed between the parties, payment shall be made to the Agency by
          BACS, direct debit or cash.<br />
          4.3 The Agency reserves the right to claim interest on late payments at a rate of 8% above
          the Bank of England base rate from time to time. Late payments shall also attract a £40
          administration charge which shall be added to the balance payable by the Client.
          </p>
          <p>
          5. DIRECTOR GUARANTEE<br />
          The directors of the Client shall act as guarantor as principal obligor and (as a separate and
          independent obligation and liability from the Client’s corporate obligations and liabilities
          under the Contract) agrees to indemnify and keep indemnified the Agency in full and on
          demand from and against all and any losses, costs, fees, legal costs, recovery costs, claims,
          liabilities, damages, demands and expenses suffered or incurred by the Agency arising out
          of, or in connection with, the Contract not being carried out or monies being recoverable 
          for any reason or any failure by the Client to perform or discharge any of its obligations or
          liabilities in respect of the Contract or these Terms and Conditions.
          </p>
          <p>
          6. CANCELLATIONS<br />
          6.1 To cancel a booking, the Client must inform the Agency immediately by sending a
          written cancellation instruction to the Agency. Cancellations will only take effect on
          the date the written cancellation instruction is received by the Agency.<br />
          6.2 Subject to clause 6.3 and clause 6.4, the following cancellation fees shall apply in
          respect of all bookings made by the Client:<br />
          6.2.1 where a cancellation is made within 30 days of the event, 100% of the agreed
          booking fee shall be payable by the Client;<br />
          6.2.2 where a cancellation is made between 31 and 60 days of the event, 50% of
          the agreed booking fee shall be payable by the Client;<br />
          6.2.3 where a cancellation is made 61+ days of the event, 25% of the agreed
          booking fee shall be payable by the Client.<br />
          6.3 Any bookings for higher value artists (£750 or more) carries a strict no cancellation
          policy and 100% of the agreed booking fee shall be payable by the Client regardless of
          when a cancellation is made.<br />
          6.4 Any bookings for New Year’s Eve carries a strict no cancellation policy and 100% of
          the agreed booking fee shall be payable by the Client regardless of when a cancellation
          was to be made.<br />
          6.5 In the event that any arrears are outstanding on a Client’s account, the Agency reserves
          the right to cancel or suspend any existing Contract with the Client and invoice the
          venue cancellation fee until payment of the arrears is made in full.<br />
          6.6 All cancellation fees due under this clause 6 shall be paid by the Client in full directly
          to the Agency within 7 days of cancellation.
          </p>
          <p>
          7. BOOKING AMENDMENTS<br />
          The Agency reserves the right to charge the Client an additional surcharge to reflect any
          amendments to a booking or performance time on or before the event. Such amendments
          include changes to performance times and extended performances. 
          </p>
          <p>
          8. ARTIST NON-SOLICITATION AND RE-ENGAGEMENT<br />
          8.1 The Client agrees that it shall not and shall procure that no other person or venue
          operated by or connected with the Client within a 50 mile radius of the Client shall
          solicit or attempt to solicit the services of the Artist otherwise than through the Agency
          for a period of 12 months from the date the Artist is booked or the Contract end date
          (whichever is the latest).<br />
          8.2 Clause 8.1 shall apply regardless of whether an Artist approaches the Client directly.<br />
          8.3 Any contravention of this clause 8 shall result in a fee being payable to the Agency of
          £150 per Artist for each booking made that is not through the Agency.
          </p>
          <p>
          9. ARTIST CANCELLATION<br />
          9.1 In the event that an Artist cancels its performance, the Agency shall inform the Client
          as soon as practicable and shall make reasonable attempts to source an alternative Artist
          that is similar in style and cost.<br />
          9.2 In the event that an alternative Artist cannot be sourced, the Client shall not be liable
          for any further fees and shall receive a refund for any payment made to the Agency in
          advance.
          </p>
          <p>
          10. FESTIVAL/ONE-OFF/ANNUAL EVENTS<br />
          The following conditions apply to bookings made by Clients in connection with any
          festival, one-off or annual events:<br />
          10.1 The Client shall be provided with a listing of Artists to book for its event (the “Listing”).
          The Client agrees that it shall not and shall procure that no other person or venue
          operated or connected with the Client shall solicit or attempt to solicit the services of
          any Artist appearing on the Listing otherwise than through the Agency for a period of
          12 months from the date that the Listing is supplied to the Client, the date the Artist is
          booked or the Contract end date (whichever is the latest).<br />
          10.2 Clause 10.1 shall apply whether or not an Artist appears at the Client’s venue or any
          alternative venue or for any other Client which is participating in such festival or event.<br />
          10.3 Any contravention of this clause 10 shall result in a fee being payable by the Client to
          the Agency of £150 per Artist for each booking made that is not through the Agency
          and/or is in contravention of this clause 10.
          </p>
          <p>
          11. AGENCY LIABILITY<br />
          11.1 The Agency will not accept any claim from the Client for any indirect or consequential
          loss of any kind, financial loss, loss of profit or loss of use howsoever caused or arising
          from the Contract.<br />
          11.2 The Agency will not accept any responsibility or liability for the actions or conduct of
          the Artist engaged under the Contract.<br />
          11.3 The Agency’s liability under the Contract shall not in any event exceed an amount
          equivalent to the agreed Contract fee.
          </p>
          <p>
          12. AUTHORITY AND CAPACITY<br />
          Each party warrants to the other that it has all requisite power and authority, and has taken
          all necessary corporate action, to enable it to enter into and perform a booking Contract
          with the other and all agreements and documents entered into, or to be entered into,
          pursuant to these Terms and Conditions.
          </p>
          <p>
          13. FORCE MAJEURE<br />
          13.1 The parties shall not be liable to the other for any failure to perform the booking
          Contract arising from circumstances outside the parties’ control.<br />
          13.2 Non-exhaustive illustration of such circumstances would be an act of God, war, riot,
          explosion, abnormal weather conditions, fire, flood, strikes, lockouts, Government
          action or regulations (UK or otherwise), unavoidable technical issues, closure or
          congestion of airports and terrorist activity.
          </p>
          <p>
          14. ENTIRE AGREEMENT<br />
          These Terms and Conditions (together with the documents referred to in them) constitute
          the entire agreement between the parties and supersedes and extinguishes all previous
          discussions, correspondence, negotiations, drafts, agreements, promises, assurances,
          warranties, representations, arrangements and understandings between them, whether
          written or oral, relating to subject matter.
          </p>
          <p>
          15. VARIATION AND WAIVER<br />
          15.1 No variation of this agreement shall be effective unless it is in writing and signed by or
          on behalf of the parties.<br />
          15.2 A waiver of any right or remedy by the Agency under these Terms and Conditions or
          by law shall only be effective in writing and shall not be deemed a waiver of any
          subsequent breach or default.<br />
          15.3 A failure or delay by the Agency to exercise any right or remedy provided under these
          Terms and Conditions or by law shall not constitute a waiver of that or any other right
          or remedy, nor shall it prevent or restrict any further exercise of that or any other right
          or remedy. No single or partial exercise of any right or remedy provided under these
          Terms and Conditions or by law shall prevent or restrict the further exercise of that or
          any other right or remedy.
          </p>
          <p>
          16. SEVERANCE<br />
          Any provision of the booking Contract which is or may be void or unenforceable shall to
          the extent of such invalidity or unenforceability be deemed severable and shall not affect
          any other provision of these Terms and Conditions.
          </p>
          <p>
          17. ASSIGNMENT<br />
          This booking Contract is personal to the parties and the parties’ shall not assign any of its
          rights or obligation under it without the others’ written consent.
          </p>
          <p>
          18. NO SET OFF<br />
          18.1 The Client shall not withhold payment of any invoice or other amount due to the
          Agency under these Terms and Conditions or otherwise by reason of any right of setoff, deduction or counterclaim which the Client may have or allege to have or for any
          reason whatever.<br />
          18.2 The Agency may at any time, without limiting any other rights or remedies it may have,
          set off any amount owing to it by the Client against any amount payable by the Agency
          to the Client.
          </p>
          <p>
          19. GOVERNING LAW & JURISDICTION<br />
          All Contracts between the Agency and the Client shall be governed in all respects by the
          law of England and the Client hereby submits to the exclusive jurisdiction of the English
          courts.
          </p>
    </div>

      )}