import React from 'react'
import './SideTab.css'
import { Link } from 'react-router-dom'

export default function SideTab() {
  return (
    <Link to={'artists'} id='side-tab' className='hover'>
        <p>Are You A Musician?</p>
    </Link>
  )
}
