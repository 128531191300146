import React from 'react'
import './Keyboard.css'

export default function Keyboard() {
  return (
    <div id='keyboard'>
        <h2>We specialise in exceptional live music that makes your venue unforgettable</h2>
        
    </div>
  )
}
