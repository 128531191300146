import React, { useState, useEffect, useRef } from 'react';
import './Benefits.css';
import guests from '../Assets/icons/guests.png';
import stay from '../Assets/icons/stay.png';
import atmosphere from '../Assets/icons/atmosphere.png';
import experience from '../Assets/icons/experience.png';
import repeat from '../Assets/icons/repeat.png';
import standout from '../Assets/icons/standout.png';
import buzz from '../Assets/icons/buzz.png';
import revenue from '../Assets/icons/revenue.png';
import engagement from '../Assets/icons/engagement.png';
import Hammer from 'hammerjs';

export default function Benefits() {
  const benefits = [
    { icon: guests, benefit: 'Attract more guests', info: 'Live music draws new customers in.' },
    { icon: stay, benefit: 'Increase length of stay', info: 'Guests remain longer, enjoying the atmosphere.' },
    { icon: atmosphere, benefit: 'Create Unique atmosphere', info: 'Live performances offer a memorable experience.' },
    { icon: experience, benefit: 'Enhance guest experience', info: 'Music elevates the overall experience.' },
    { icon: repeat, benefit: 'Boost repeat business', info: 'Regular live events encourage repeat visits.' },
    { icon: standout, benefit: 'Stand out from competitors', info: 'Distinguish your venue with unique live offerings.' },
    { icon: buzz, benefit: 'Drive social media buzz', info: 'Live events create shareable moments for online promotion.' },
    { icon: revenue, benefit: 'Increase revenue', info: 'More guests and longer stays translate to higher sales.' },
    { icon: engagement, benefit: 'Community engagement', info: 'Live music builds a connection with the local scene.' },
  ];

  const [step, setStep] = useState(1);
  const sliderRef = useRef(null);

  // Handle transition to next or previous step for desktop
  const handleStepChange = (newStep) => {
    setStep(newStep);
    const slider = sliderRef.current;
    if (slider) {
      slider.style.transform = `translateX(-${(newStep - 1) * 100}vw)`;
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    
    // Ensure Hammer.js options are properly set
    const hammer = new Hammer(slider, { domEvents: true });
  
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
  
    hammer.on('swipeleft', () => {
      if (step < benefits.length) {
        handleStepChange(step + 1);
      }
    });
  
    hammer.on('swiperight', () => {
      if (step > 1) {
        handleStepChange(step - 1);
      }
    });
  
    return () => {
      hammer.destroy();
    };
  }, [step]);

  return (
    <div id="benefits">
      <h2>Boost your business with live music</h2>
      <p id="benefit-overview">
        Explore the key benefits of incorporating live performances into your establishment and see how it can transform your business.
      </p>

      <div id="benefits-slider-container">
        <div ref={sliderRef} id="benefits-slider" style={{ transition: 'transform 0.5s ease' }}>
          {benefits.map((benefit, index) => (
            <div className="benefit-container" key={index}>
              <img className="benefit-icon" src={benefit.icon} alt={benefit.benefit} />
              <div>
                <h3>{benefit.benefit}</h3>
                <p>{benefit.info}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Step Selector for Desktop */}
      <div id="steps">
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={index}
            className={step === index + 1 ? 'step-selector-active hover' : 'step-selector hover'}
            onClick={() => handleStepChange(index + 1)}
          />
        ))}
      </div>

      {/* Mobile Step Indicator */}
      <div id="mobile-step-indicator">
        {benefits.map((_, index) => (
          <div
            key={index}
            className={step === index + 1 ? 'mobile-step mobile-step-active' : 'mobile-step'}
          />
        ))}
      </div>

      <p style={{ marginTop: 50, marginBottom: 20, color: '#FBFCEA', fontSize: 22, textAlign: 'center', maxWidth:'95%' }}>
        Ready to elevate the atmosphere at your venue?
      </p>

      <button className="mg-btn" onClick={() => handleStepChange(1)}>
        Book A Call
      </button>
    </div>
  );
}