import React from 'react'
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Components/Home';
import Terms from './Components/Terms';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Artists from './Components/Artists';
import ScrollToTop from './Components/ScrollToTop'

function App() {

  return (
    <>
      <ToastContainer position='top-center' />
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/t-c-s" element={<Terms />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
